import React from "react";
import Slider from "react-slick";
import '../../App.css';
import Avatar from '@mui/material/Avatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Title from './Title';
import ListDiscpline from "./ListDiscipline";


function Discipline({ classe }) {
    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
          <div
            className={className}
            style={{ ...style, display: "none", background: "red" }}
            onClick={onClick}
          />
        );
      }
      
      function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
          <div
            className={className}
            style={{ ...style, display: "none", background: "green" }}
            onClick={onClick}
          />
        );
      }
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    vertical: true,
    verticalSwiping: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />

  };
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div className="slider-container">
        <span style={{fontSize:'20px', fontWeight:'bold', textAlign:'center', color:'#ED462F', lineHeight:'14px'}}>Notes de discipline</span>
      <Slider {...settings}>
        {classe.map((label) => {
          const borderColor = label.discipline >= 5 ? 'green' : 'red';

          return (
            <div key={label.id}>
              <ListItemButton style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between', heigth:'fit-content' }} onClick={handleClickOpen}>
                <div style={{ display: 'flex', alignItems: 'center', overflow:'hidden' }}>
                  <ListItemIcon>
                    <Avatar alt={`${label.prenom} ${label.nom}`} src={`${label.photo}`} />
                  </ListItemIcon>
                  <ListItemText primary={`${label.prenom} `} />
                </div>
                <div 
                  id="circlemark" 
                  style={{ 
                    border: `3px solid ${borderColor}`, 
                    borderRadius: '50%', 
                    width: '30px', 
                    height: '30px', 
                    marginRight: '30px', 
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'center'
                  }}>
                  {label.discipline}
                </div>
              </ListItemButton>
              <ListDiscpline open={open} handleClickOpen={handleClickOpen} handleClose={handleClose} label={label}/>
            </div>
          );
        })}
      </Slider>
      <ListDiscpline open={open} handleClickOpen={handleClickOpen} handleClose={handleClose} classe={classe}/>

    </div>
  );
}

export default Discipline;
