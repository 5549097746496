import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Avatar from '@mui/material/Avatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

export default function ListDiscpline({ open, classe = [], handleClose }) {
    // `classe` est initialisé à un tableau vide par défaut si undefined
    const rows = Array.isArray(classe) ? classe : [];
  
    return (
      <React.Fragment>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
        
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell><strong>Noms</strong></TableCell>
                    <TableCell><strong>Notes</strong></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((label) => {
                    const borderColor = label.discipline >= 5 ? 'green' : 'red';
  
                    return (
                      <TableRow key={label.id}>
                        <TableCell>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <ListItemIcon>
                              <Avatar alt={`${label.prenom} ${label.nom}`} src={`${label.photo}`} />
                            </ListItemIcon>
                            <ListItemText primary={`${label.prenom} ${label.nom}`} />
                          </div>
                        </TableCell>
                        <TableCell>
                          <div 
                            id="circlemark" 
                            style={{ 
                              border: `3px solid ${borderColor}`, 
                              borderRadius: '50%', 
                              width: '30px', 
                              height: '30px', 
                              marginRight: '30px', 
                              display: 'flex', 
                              alignItems: 'center', 
                              justifyContent: 'center'
                            }}
                          >
                            {label.discipline}
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Fermer</Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
  