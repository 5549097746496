import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import List from '@mui/material/List';
import Avatar from '@mui/material/Avatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import BarChartIcon from '@mui/icons-material/BarChart';
import ChecklistIcon from '@mui/icons-material/Checklist';
import ClassIcon from '@mui/icons-material/Class';
import LogoutIcon from '@mui/icons-material/Logout';
import axios from 'axios';
import Infos from './Infos';
import PersonalProgress from './PersonalProgress';
import Evaluations from './Evaluations';
import Activites from './Activites';
import CircularProgress from '@mui/material/CircularProgress'; 
import '../../App.css';

import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import Profil from './Profil';


const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'absolute',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);



export default function Dashboard({ logout, userID, currentUser }) {
  const [open, setOpen] = React.useState(true);
  const [value, setValue] = React.useState(0);
  const [loading, setLoading] = React.useState(true); 
  const [isAnActivity, setIsAnActivity] = React.useState('')
  const [filteredUsers, setFilteredUsers] = React.useState([]);
  const [classe, setClasse] = React.useState([]);
  const [topstudents, setTopstudents] = React.useState([]);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const toggleTo0 = () => {
    setValue(0);
  };
  const toggleTo1 = () => {
    setValue(1);
  };
  const toggleTo2 = () => {
    setValue(2);
  };
  const toggleTo3 = () => {
    setValue(3);
  };
  const toggleTo4 = () => {
    setValue(4);
  };

  

  

  React.useEffect(() => {
    getArray();
    
    // Fonction pour fermer le tiroir si l'écran est inférieur à 600px
    const handleResize = () => {
      if (window.innerWidth < 600) {
        setOpen(false);
      }
    };

    // Écouteur d'événement pour les changements de taille d'écran
    window.addEventListener('resize', handleResize);

    // Ferme le tiroir au chargement initial si l'écran est inférieur à 600px
    if (window.innerWidth < 600) {
      setOpen(false);
    }

    // Nettoyage de l'écouteur d'événement lorsque le composant est démonté
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const [briefs, setBriefs] = React.useState([]);



  const getArray = async () => {
    try {
      const response = await axios.get('https://backend-codeschool241.onrender.com/etudiants');
      const etudiants = response.data;


      const response2 = await axios.get(`https://backend-codeschool241.onrender.com/briefs`);
      setBriefs(response2.data)
  
      if (etudiants && etudiants.length > 0) {
        etudiants.forEach(etudiant => {
          if (typeof etudiant.competences === 'string' && typeof etudiant.briefs === 'string') {
            etudiant.competences = JSON.parse(etudiant.competences);
            etudiant.briefs = JSON.parse(etudiant.briefs);
          }
        });
  
        const filtered = etudiants.filter(user => user.id === currentUser.uid);
        const filteredClasse = etudiants.filter(user => user.referentiel === filtered[0].referentiel);
        setFilteredUsers(filtered);
        setClasse(filteredClasse);
        
        const sortedArray = filteredClasse.slice().sort((a, b) => {
          const totalNiveauA = a.competences.reduce((acc, competence) => acc + competence.niveau, 0);
          const totalNiveauB = b.competences.reduce((acc, competence) => acc + competence.niveau, 0);
          return totalNiveauB - totalNiveauA;
        });
  
        setTopstudents(sortedArray);
      } else {
        console.error("Les données sont vides ou incorrectes.");
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des données", error);
    } finally {
      setLoading(false);
    }
  };

  

    


  function getStepContent(value) {
    switch (value) {
      case 0:
        return <Infos setIsAnActivity={setIsAnActivity} isAnActivity={isAnActivity}  briefs={briefs} topstudents={topstudents} classe={classe} filteredUsers={filteredUsers} />;
      case 1:
        return <PersonalProgress filteredUsers={filteredUsers} />;
      case 2:
        return <Evaluations topstudents={topstudents} classe={classe} filteredUsers={filteredUsers} />;
      case 3:
        return <Profil topstudents={topstudents} classe={classe} filteredUsers={filteredUsers} />;
      case 4:
        return <Activites briefs={briefs} classe={classe} filteredUsers={filteredUsers} />;
      
        default:
        throw new Error('Unknown step');
    }
  }
  const lien = filteredUsers.length > 0 ? `${filteredUsers[0].photo}` : '/static/images/avatar/default.jpg';
  return (
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: '24px',
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              Dashboard
            </Typography>
            <IconButton color="inherit">
              <Badge badgeContent={0} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>

          <List component="nav" sx={{ my: 3, display:'flex', flexDirection:'column', justifyContent:'space-between', height: '100%' }}>
            <div>
              <ListItemButton className='avatarpadding' sx={{p:1}} onClick={toggleTo3}>
                <ListItemIcon> 
                  {filteredUsers.length > 0 ? (
                    <Avatar alt={`${filteredUsers[0].prenom} ${filteredUsers[0].nom}`} src={lien}/>
                  ) : (
                    <Avatar alt="Utilisateur inconnu" src="/static/images/avatar/default.jpg"/>
                  )}
                </ListItemIcon>
                <ListItemText primary={filteredUsers.length > 0 ? `Profil` : "Utilisateur inconnu"} sx={{marginLeft:'10px'}} />
              </ListItemButton>

              <Divider sx={{ my: 1 }} />
              <ListItemButton onClick={toggleTo0}>
                <ListItemIcon>
                  <BarChartIcon />
                </ListItemIcon>
                <ListItemText primary="Tableau de bord" />
              </ListItemButton>
              <ListItemButton onClick={toggleTo1}>
                <ListItemIcon>
                  <ChecklistIcon />
                </ListItemIcon>
                <ListItemText primary="Avancements" />
              </ListItemButton>
              <ListItemButton onClick={toggleTo4} >
                <ListItemIcon>
                  <HistoryEduIcon />
                </ListItemIcon>
                <ListItemText primary="Activités" />
              </ListItemButton>
              <ListItemButton onClick={toggleTo2} >
                <ListItemIcon>
                  <ClassIcon />
                </ListItemIcon>
                <ListItemText primary="Evaluations" />
              </ListItemButton>

            </div>
            <div>
              <Divider sx={{ my: 1 }} />
              <ListItemButton onClick={logout}>
                <ListItemIcon>
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText primary="Se déconnecter" />
              </ListItemButton>
            </div>
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
            
          }}
          className='size-box-drawer'
        >
          <Toolbar />
          {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
              <CircularProgress />
            </Box>
          ) : (
            getStepContent(value)
          )}
        </Box>
      </Box>
  );
}
