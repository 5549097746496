import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import '../../App.css';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import SendIcon from '@mui/icons-material/Send';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import ListApprenant from './ListApprenant';
import TransferList from './TransferList';
import NewBriefCheckout from './NewBriefCheckout'
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "../../firebase";


const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

// ... autres imports et code

function AddBrief({classe}) {
  const [formData, setFormData] = useState({

    titre: '',
    message: '',
    date: null,
    file: null,
  });

  const [left, setLeft] = useState(classe);
  const [right, setRight] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const datas = [right, formData];



  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      message: value,
    }));
  };
  const handleChangeTitre = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      titre: value,
    }));
  };

  const handleDateChange = (newDate) => {
    setFormData((prevData) => ({
      ...prevData,
      date: newDate,
    }));
  };

  const [file, setFile] = useState(null);

  const handleFileChangeFile = (e) => {
    setFile(e.target.files[0]);
    console.log(e.target.files[0]);
    

  };
  const [fichier, setfichier] = useState('');
  const handleUpload = () => {
    if (!file) return;

    const storageRef = ref(storage, `/files/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
      },
      (error) => {
        console.log(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          console.log("File available at", downloadURL);
          setfichier(downloadURL);
        });
      }
    );
  };
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    if (activeStep === 1) {
      handleUpload()
    }
  };

  const goLiive = () => {
    setIsLoading(true); // Désactiver le bouton et afficher le chargement



        for (let index = 0; index < datas[0].length; index++) {
          const element = datas[0][index].briefs;

          const brief = {
            id: new Date(),
            titre: `${datas[1].titre}`,
            date: `${datas[1].date.$D}-${datas[1].date.$M + 1}-${datas[1].date.$y}`,
            source: `/${fichier}`,
            statut: 0,
            description: `${datas[1].message}`,
          };

          element.push(brief);
          const newArray = JSON.stringify(element);
          const data = { id: datas[0][index].id, brief: newArray };

          axios
            .put(`https://backend-codeschool241.onrender.com/addbrief/${data.id}`, data)
            .then((response) => {
              console.log('Données envoyées avec succès:', response.data);
              setIsSuccess(true); // Action réussie
              window.location.reload();

              
            })
            .catch((error) => {
              console.error("Erreur lors de l'envoi des données:", error);
              setIsSuccess(false); // Action échouée
            });
        }
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  function getStepContent(activeStep) {
    switch (activeStep) {
      case 0:
        return (
          <div className='case0'>
            <h2 className='boldtitle' style={{ marginTop: '0' }}>Détails du brief</h2>
            <TextField
              id="outlined-multiline-static"
              label="Titre"
              multiline
              rows={1}
              name="message"
              value={formData.titre}
              onChange={handleChangeTitre}
              sx={{ width: '100%', marginBottom: '15px' }}
            />
            <TextField
              id="outlined-multiline-static"
              label="Description"
              multiline
              rows={6}
              name="message"
              value={formData.message}
              onChange={handleChange}
              sx={{ width: '100%', marginBottom: '7px' }}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DatePicker', 'DatePicker']}>
                <DatePicker
                  label="Date de livraison"
                  value={formData.date}
                  onChange={handleDateChange}
                  sx={{ width: '100%' }}
                />
              </DemoContainer>
            </LocalizationProvider>
            <Button
              component="label"
              role={undefined}
              variant="contained"
              tabIndex={-1}
              startIcon={<CloudUploadIcon />}
              sx={{ width: '100%', marginTop: '15px' }}
            >
              Ajouter un fichier
              <VisuallyHiddenInput type="file" onChange={handleFileChangeFile} />
            </Button>
            <div style={{ height: '30px', display: 'flex', justifyContent: 'center' }}>
              <span style={{ textAlign: 'center', width: '100%', fontSize: '18px' }}>
                {file ? file.name : 'Aucun fichier sélectionné'}
              </span>
            </div>

          </div>
        );

      case 1:
        return (
          <div>
            <h2 className="boldtitle" style={{ marginTop: '0' }}>Destinataire(s)</h2>

            <TransferList
              classe={classe}
              setLeft={setLeft}
              setRight={setRight}
              left={left}
              right={right}
            />
          </div>
        );

      case 2:
        return (
          <NewBriefCheckout
            right={right}
            datas={datas}
          />
        );

      default:
        throw new Error('Unknown step');
    }
  }


    return ( 

        <Grid item xs={12} md={4} lg={12}>
            <Paper
                sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                height: 520,
                justifyContent: 'space-between',
                }}
            >
                {getStepContent(activeStep)}
                <div style={{ height: '50px' }}>
                <MobileStepper
                    variant="dots"
                    steps={3}
                    position="static"
                    activeStep={activeStep}
                    sx={{ maxWidth: '100%', flexGrow: 1, height: 'fit-content' }}
                    nextButton={
                    <div>
                        {activeStep === 2 ? (
                        <Button
                            variant="contained"
                            endIcon={isLoading ? <CircularProgress size={24} color="inherit" /> : <SendIcon />}
                            onClick={goLiive}
                            disabled={isLoading} // Désactiver le bouton lors du chargement
                        >
                            Envoyer
                        </Button>
                        ) : (
                        <Button size="small" onClick={handleNext} disabled={activeStep === 5}>
                            Suivant
                            {theme.direction === 'rtl' ? (
                            <KeyboardArrowLeft />
                            ) : (
                            <KeyboardArrowRight />
                            )}
                        </Button>
                        )}
                    </div>
                    }
                    backButton={
                    <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                        {theme.direction === 'rtl' ? (
                        <KeyboardArrowRight />
                        ) : (
                        <KeyboardArrowLeft />
                        )}
                        Retour
                    </Button>
                    }
                />
                </div>
            </Paper>
            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <MuiAlert onClose={handleSnackbarClose} severity={isSuccess ? 'success' : 'error'} sx={{ width: '100%' }}>
                {isSuccess ? 'Action réussie !' : 'Erreur lors de l\'action.'}
                </MuiAlert>
            </Snackbar>
        </Grid>

     );
}

export default AddBrief;