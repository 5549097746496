// firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import axios from 'axios';



const firebaseConfig = {
    apiKey: "AIzaSyBFsTdqPSNL268ri7Xe0X5FxpS7nOIg1qc",
    authDomain: "auth-2a5c6.firebaseapp.com",
    projectId: "auth-2a5c6",
    storageBucket: "auth-2a5c6.appspot.com",
    messagingSenderId: "824837136835",
    appId: "1:824837136835:web:8a505fedeb1444935a5d76",
    measurementId: "G-522X2NX0E1"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const provider = new GoogleAuthProvider();

export { db, auth, provider, storage };


// Initialize Firebase Cloud Messaging and get a reference to the service
const messaging = getMessaging();


export const requestForToken = () => {
    return getToken(messaging, { vapidKey: 'BGJ1Uy2fUSQksq0xvm1_AfbhfQDNw2jaSVwnYNCwUsDFCckF6jjzIwEVNZFrmN__u5SrC_oTi2a3NcHbLGrryjM' })
      .then((currentToken) => {
        if (currentToken) {
          // Perform any other neccessary action with the token
          
            // Créer l'objet de données
          const data = {
            token: currentToken,
          };

          // Envoyer les données au backend via axios
          axios.post('https://backend-codeschool241.onrender.com/api/save-data', data)
            .then(response => {

              console.log('Succès:', response.data);
            })
            .catch(error => {
              console.error('Erreur:', error);
              console.log('Une erreur s\'est produite. Veuillez réessayer.');

              // console.log('Succès:', response.data);
            })
            .catch(error => {
              console.error('Erreur:', error);
              // console.log('Une erreur s\'est produite. Veuillez réessayer.');
            });

        } else {
          // Show permission request UI
          // console.log('No registration token available. Request permission to generate one.');
        }
      })
      .catch((err) => {
        // console.log('An error occurred while retrieving token. ', err);
      });
  };

  export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("payload", payload)
      resolve(payload);
    });
  });

