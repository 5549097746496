import * as React from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from '../Title';
import "./CurrentTopStudents.css"

// Generate Order Data
function createData(id, date, name, shipTo, paymentMethod, amount) {
  return { id, date, name, shipTo, paymentMethod, amount };
}


function preventDefault(event) {
  event.preventDefault();
}

export default function CurrentTopStudents({topstudents}) {
  return (
    <React.Fragment>
      <span style={{fontSize:'20px', fontWeight:'bold', textAlign:'start', color:'#ED462F', lineHeight:'14px'}}>Top 3 de la cohorte</span>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell><strong>Noms</strong> </TableCell>
            <TableCell><strong>Prénoms</strong> </TableCell>
            <TableCell><strong>Spécialisation</strong> </TableCell>

          </TableRow>
        </TableHead>
        <TableBody>
          {topstudents.slice(0, 3).map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row.nom}</TableCell>
              <TableCell>{row.prenom}</TableCell>
              <TableCell>{row.specialisation}</TableCell>

            </TableRow>
          ))}
        </TableBody>
      </Table>
      {/* <Link color="primary" href="#" onClick={preventDefault} sx={{ mt: 3 }}>
        See more CurrentTopStudents
      </Link> */}
    </React.Fragment>
  );
}
