import React, { useState } from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import '../../App.css';
import ListApprenant from './ListApprenant';
import AddBrief from './AddBrief';
import Briefs from './Briefs';
import Etudiant from './Etudiant';
import GlobalGauge from '../StudentDashboard/GlobalGauge/GlobalGauge';
import BaddestGauge from '../StudentDashboard/BaddestGauge/BaddestGauge';
import Tracker from './Tracker';







function AdminThirdModule({ classe, filteredUsers }) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3} className='row1'>
        <Tracker 
        classe={classe}
        open={open}
        setOpen={setOpen}
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
        filteredUsers={filteredUsers}
        />

      </Grid>



      
    </Container>
  );
}

export default AdminThirdModule;
