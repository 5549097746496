import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import InfoEtudiant from './InfoEtudiant';
import BriefsApprenant from './BriefsApprenant';
import PersonalProgress from './PersonalProgress';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Etudiant(
    {
        open,
        selectedStudent,
        handleClose
    }
) {
  console.log(selectedStudent);
  
  return (
    <React.Fragment>

      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
  
            <Button variant="outlined" startIcon={<CloseIcon />} onClick={handleClose}>
            Fermer
            </Button>
            

          </Toolbar>
        </AppBar>
        <div style={{display:'flex', padding: '20px'}}>
            <InfoEtudiant
            selectedStudent={selectedStudent}
            />
            <BriefsApprenant
            classe={[selectedStudent]}
            />

        </div>
        <div style={{display:'flex', padding: '20px'}}>
        <PersonalProgress
        selectedStudent={selectedStudent}
        />

        </div>

      </Dialog>
    </React.Fragment>
  );
}
