import React, { useState, useEffect } from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import DashboardDev from '../Composants/AdminDashboard/DashboardDev';

function Dev() {

    
    return ( 
        <div>
            <DashboardDev/>
        </div>
     );
}

export default Dev;