import React, { useState, useEffect } from 'react';
import ChangeInformations from './ChangeInformations';
import ChangePassword from './ChangePassword';


function Profil({topstudents,classe, filteredUsers}) {
    
    return ( 
        <div>
            <ChangeInformations classe={classe} filteredUsers={filteredUsers}/>
            <ChangePassword topstudents={topstudents} classe={classe} filteredUsers={filteredUsers}/>
        </div>
     );
}

export default Profil;