import React, { useState, useEffect } from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import { auth } from '../../firebase';
import { updatePassword } from "firebase/auth";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';

function ChangePassword() {
    const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleChangePassword = () => {
    setError('');
    setSuccess('');

    if (newPassword !== confirmPassword) {
      setError("Les mots de passe ne correspondent pas.");
      return;
    }

    const user = auth.currentUser;
    if (user) {
      updatePassword(user, newPassword)
        .then(() => {
          setSuccess("Le mot de passe a été changé avec succès.");
        })
        .catch((error) => {
          setError("Erreur lors du changement de mot de passe : " + error.message);
        });
    } else {
      setError("Aucun utilisateur n'est connecté.");
    }
  };

    return ( 
        <div>
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4} lg={4}>
                        <Paper
                            sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            height: 'fit-content',
                            justifyContent: 'space-between',
                            }}
                        >
                            <div style={{ maxWidth: '400px', margin: 'auto' }}>
                            <h2 className='boldtitle' style={{textAlign:'center'}}>Changer son mot de passe</h2>
                            {error && <Alert severity="error">{error}</Alert>}
                            {success && <Alert severity="success">{success}</Alert>}
                            <TextField
                                label="Nouveau mot de passe"
                                variant="outlined"
                                type="password"
                                fullWidth
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                margin="normal"
                            />
                            <TextField
                                label="Confirmer le mot de passe"
                                variant="outlined"
                                type="password"
                                fullWidth
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                margin="normal"
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={handleChangePassword}
                                style={{ marginTop: '16px' }}
                            >
                                Changer le mot de passe
                            </Button>
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </div>
     );
}

export default ChangePassword;