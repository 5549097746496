import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import React from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import "../../App.css"
import Skillslevels from '../StudentDashboard/Skillslevels';




function PersonalProgress({selectedStudent}) {
    var array = selectedStudent.competences
    console.log(selectedStudent);
    

    return ( 
        

            <Grid container spacing={3} sx={{pl:3}}>
                <Grid lg={12}>
                    <Paper
                    sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems:'center',
                        height: "100%",
                    }}
                    >
                        <span style={{fontSize:'20px', fontWeight:'bold', textAlign:'center', color:'#ED462F'}}> Niveaux de compétences</span>
                        <br /><br />
                        <div style={{width:'100%'}} className='head-table-skills'>
                            <div style={{display:'flex', justifyContent:'space-between', width:'100%', marginBottom: "20px", alignItems:'center'}}  >
                            <h3 style={{margin:0}}>Compétences</h3>
                            <h3 style={{margin:0}}>Niveaux</h3>
                            </div>
                            <Divider/>
                        </div>
                        {array.map((label) => {
                            
                            return(
                        <div style={{width:'100%'}} key={label.titre}>
                            <div className='main-progress-box'>
                            <span>{label.titre}</span>
                            <Skillslevels niveau={label.niveau}/>
                            </div>
                            <Divider/>
                        </div>
                        

                        )})}
                    </Paper>
                </Grid>
            </Grid>
     );
}

export default PersonalProgress;