import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import React from 'react';
import Title from './Title';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import "../../App.css"
import Skillslevels from './Skillslevels';




function PersonalProgress({filteredUsers}) {
    var array = filteredUsers[0].competences

    return ( 
        
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={8} lg={12}>
                    <Paper
                    sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems:'center',
                        height: "100%",
                    }}
                    >
                        <span style={{fontSize:'20px', fontWeight:'bold', textAlign:'center', color:'#ED462F', lineHeight:'14px'}}> Niveaux de compétences</span>
                        <br /><br />
                        <div style={{width:'100%'}} className='head-table-skills'>
                            <div style={{display:'flex', justifyContent:'space-between', width:'100%', margin: "20px 0", alignItems:'center'}}  >
                            <h3>Compétences</h3>
                            <h3>Niveaux</h3>
                            </div>
                            <Divider/>
                        </div>
                        {array.map((label) => {
                            
                            return(
                        <div style={{width:'100%'}} key={label.titre}>
                            <div className='main-progress-box'>
                            <span>{label.titre}</span>
                            <Skillslevels niveau={label.niveau}/>
                            </div>
                            <Divider/>
                        </div>
                        

                        )})}
                    </Paper>
                </Grid>
            </Grid>
        </Container>
     );
}

export default PersonalProgress;