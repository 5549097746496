import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ProgressBar from './ProgressBar';
import Divider from '@mui/material/Divider';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Gauge } from '@mui/x-charts/Gauge';
import axios from 'axios';



const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
  ...theme.applyStyles('dark', {
    backgroundColor: 'rgba(255, 255, 255, .05)',
  }),
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function AccordionTracker({ filteredUsers }) {
    const [expanded, setExpanded] = React.useState('panel1');
    const [array, setarray] = React.useState([]);
    const [objectifs, setobjectifs] = React.useState([]);
    const [briefs, setbriefs] = React.useState([]);
  
    const handleChange = (panel) => (event, newExpanded) => {
      setExpanded(newExpanded ? panel : false);
    };
  
    React.useEffect(() => {
      const checkAuth = async () => {
        const response = await axios.get('https://backend-codeschool241.onrender.com/modules');
        setarray(response.data);
        const response2 = await axios.get('https://backend-codeschool241.onrender.com/objectifs');
        setobjectifs(response2.data);
        const response3 = await axios.get('https://backend-codeschool241.onrender.com/briefs');
        setbriefs(response3.data);
      };
      checkAuth();
    }, []);
  
    const modules = array.filter(module => module.cohorte === filteredUsers[0].referentiel);
  
    // Fonction pour convertir les dates au format JJ-MM-AAAA en objet Date
    const parseDate = (dateStr) => {
      const [day, month, year] = dateStr.split("-");
      return new Date(year, month - 1, day);
    };
  
    return (
      <div>
        {modules.map((module) => {
          const objectifsfiltrés = objectifs.filter(objectif => objectif.id_module === module.id_module);
  
          // Calculer la durée du module en jours
          const start = parseDate(module.debut);
          const end = parseDate(module.fin);
          const diffInMs = end - start;
          const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
  
          // Tableau pour stocker les valeurs de progression des objectifs
          let totalValeur = 0;
          objectifsfiltrés.forEach((objectif) => {
            const filteredbriefs = briefs.filter(module => module.id_objectif === objectif.id_objectif);
            const uniqueBriefs = [];
            const studentIds = new Set();
  
            filteredbriefs.forEach(brief => {
              if (!studentIds.has(brief.id_etudiant)) {
                studentIds.add(brief.id_etudiant);
                uniqueBriefs.push(brief);
              }
            });
  
            const briefWithStatus1 = filteredbriefs.filter(module => module.statut === 2);
            const valeur = Math.floor(briefWithStatus1.length / uniqueBriefs.length * 100);
            totalValeur += valeur; // Additionner les valeurs pour calculer la moyenne
          });
  
          // Calculer la moyenne de "valeur" par rapport au nombre d'objectifs
          const moyenneValeur = objectifsfiltrés.length > 0 ? Math.floor(totalValeur / objectifsfiltrés.length) : 0;
  
          return (
            <Accordion key={module.id_module} expanded={expanded === `panel${module.id_module}`} onChange={handleChange(`panel${module.id_module}`)}>
              <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                  <Typography>{module.module}</Typography>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {/* Utiliser la moyenne calculée */}
                    <span style={{ margin: '0 5px 0px 20px' }}>{moyenneValeur}%</span>
                    <div style={{ width: '200px', }}>
                      <ProgressBar value={moyenneValeur} />
                    </div>
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <Typography sx={{ fontWeight: 'bold' }}>
                  Description:
                </Typography>
                <Typography>
                  {module.descriprion}
                </Typography>
                <br />
                <Typography sx={{ fontWeight: 'bold' }}>
                  Durée:
                </Typography>
                <Typography>
                  {diffInDays} jours (Du {module.debut} au {module.fin})
                </Typography>
                <br />
                <Typography sx={{ fontWeight: 'bold' }}>
                  Objectifs:
                </Typography>
                <div style={{ width: '100%' }}>
                  {objectifsfiltrés.map((objectif) => {
                    const filteredbriefs = briefs.filter(module => module.id_objectif === objectif.id_objectif);
                    const uniqueBriefs = [];
                    const studentIds = new Set();
  
                    filteredbriefs.forEach(brief => {
                      if (!studentIds.has(brief.id_etudiant)) {
                        studentIds.add(brief.id_etudiant);
                        uniqueBriefs.push(brief);
                      }
                    });
  
                    const briefWithStatus1 = filteredbriefs.filter(module => module.statut === 2);
                    const valeur = Math.floor(briefWithStatus1.length / uniqueBriefs.length * 100);
                    console.log(briefWithStatus1);
                    
                    return (
                      <div key={objectif.id_objectif}>
                        <div className='main-progress-box'>
                          <div style={{ display: 'flex', alignContent: 'center', margin: '10px 0' }}>
                            <CheckCircleOutlineIcon />
                            <span style={{ marginLeft: '15px' }}>{objectif.objectif}</span>
                          </div>
                          <div style={{ height: '50px', width: '50px' }}>
                            <Gauge width={100} height={100} value={valeur} style={{ height: '100%', width: '100%' }} className='GlobalGauge' />
                          </div>
                        </div>
                        <Divider />
                      </div>
                    );
                  })}
                </div>
                <br />
                <Typography sx={{ fontWeight: 'bold' }}>
                  Compétence(s) abordée(s):
                </Typography>
                {module.competences.map((competence, index) => {
                  return (
                    <span key={index}>
                      {competence}
                      {index === module.competences.length - 1 ? "." : ", "}
                    </span>
                  );
                })}
              </AccordionDetails>
            </Accordion>
          );
        })}
      </div>
    );
  }
  
