import React, { useState, useEffect } from "react";
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Avatar from '@mui/material/Avatar';
import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import Brief from "./Brief";

import Divider from '@mui/material/Divider';


// Fonction pour convertir les dates au format JJ-MM-AAAA en un objet Date
const parseDate = (dateStr) => {
    const [day, month, year] = dateStr.split("-");
    return new Date(year, month - 1, day);
  };
  const today = new Date();
  
  const Briefs = ({
    classe
}) => {
 // État pour stocker les briefs avec le nombre d'apprenants et leurs noms
 const [briefs, setBriefs] = useState([]);

 useEffect(() => {
   // Créer un dictionnaire pour regrouper les briefs par titre et compter les apprenants
   const briefsMap = {};

   // Parcourir tous les apprenants et leurs briefs
   classe.forEach(apprenant => {
     apprenant.briefs.forEach(brief => {
       // Si le brief n'existe pas encore dans le dictionnaire, le créer
       if (!briefsMap[brief.titre]) {
         briefsMap[brief.titre] = {
           titre: brief.titre,
           date: brief.date,
           statut: brief.statut,
           source: brief.source,
           description: brief.description,
           apprenants: [{ nom: apprenant.nom, prenom: apprenant.prenom }]
         };
       } else {
         // Sinon, ajouter l'apprenant à la liste existante
         briefsMap[brief.titre].apprenants.push({
           nom: apprenant.nom, prenom: apprenant.prenom, photo: apprenant.photo
         });
       }
     });
   });

   

   // Transformer le dictionnaire en un tableau de briefs pour faciliter l'affichage
   const sortedBriefs = Object.values(briefsMap);

   console.log(sortedBriefs);

   // Trier les briefs
   const BriefsToMap = sortedBriefs.sort((a, b) => {
    // Placer les briefs avec statut 0 en premier
    if (a.statut === 0 && b.statut !== 0) return -1;
    if (a.statut !== 0 && b.statut === 0) return 1;

    // Si les deux briefs ont un statut 0, trier par date décroissante
    if (a.statut === 0 && b.statut === 0) {
      return parseDate(b.date) - parseDate(a.date);
    }

    // Si les deux briefs n'ont pas le statut 0, ne rien faire (ordre quelconque)
    return 0;
  });

  setBriefs(BriefsToMap); // Mettre à jour l'état avec la liste triée des briefs
 }, [classe]);

 const [open, setOpen] = React.useState(false);

 const handleClickOpen = () => {
   setOpen(true);
 };

  return (
    <div style={{width:'100%'}}>

      <Grid   lg={12} sx={{pt:'24px', pl: '24px'}}>
            <Paper
                sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 520
                }}
            >
                <h2 className="boldtitle">Liste des Briefs</h2>
                <TableContainer sx={{ maxHeight: 440, paddingLeft: 0, width:'100%' }}>
                    <List sx={{ width: '100%', padding: 0 }}>
                    {briefs.map((brief, index) => {


                            return (
                                <div key={index}>
                                    {/* <ListItemButton onClick={handleClickOuverture}> */}

                                        <Brief 
                                            handleClickOpen={handleClickOpen}
                                            setOpen={setOpen}
                                            open={open}
                                            brief={brief}
                                            parseDate={parseDate}
                                            today={today}
                                        />
                                    <Divider />
                                </div>
                            );
                        })}
                    </List>
                </TableContainer>
            </Paper>
        </Grid>
    </div>
  );
};

export default Briefs;
