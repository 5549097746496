import React, { useState, useEffect } from "react";
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Avatar from '@mui/material/Avatar';
import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import Activity from './Activity';  // Remplacez 'Actvity' par 'Activity'
import Divider from '@mui/material/Divider';
import axios from "axios";


// Fonction pour convertir les dates au format JJ-MM-AAAA en un objet Date
const parseDate = (dateStr) => {
    const [day, month, year] = dateStr.split("-");
    return new Date(year, month - 1, day);
  };
  const today = new Date();
  
  const Activities = ({
    classe
}) => {
 // État pour stocker les briefs avec le nombre d'apprenants et leurs noms
  const [briefs, setBriefs] = useState([]);
  const [allbriefs, setallBriefs] = useState([]);
  const [numberOfbriefs, setNumberOfBriefs] = useState([]);

  const getData = async () => {
    const response = await axios.get(`https://backend-codeschool241.onrender.com/briefs-uniques-${classe[0].referentiel === 'Développeur web' ? 'dev' : 'ref '}`);
    const response2 = await axios.get(`https://backend-codeschool241.onrender.com/briefs`);
    const response3 = await axios.get(`https://backend-codeschool241.onrender.com/reponses`);
    setBriefs(response.data)
    console.log(response.data)
    setallBriefs(response3.data)
    setNumberOfBriefs(response2.data)
  } 


  useEffect(() => {
    console.log(classe);
    
    getData()
  }, []);

 const [open, setOpen] = React.useState(false);

 const handleClickOpen = () => {
   setOpen(true);
 };
 const [message, setMessage] = useState('');
 const [isLoading, setIsLoading] = useState(false);
 const [isOpen, setIsOpen] = useState(true);
 const [titre, setTitre] = useState('');
 // Fonction pour convertir une date au format jj-mm-aaaa en objet Date
function parseDate(dateStr) {
  const [day, month, year] = dateStr.split('-').map(Number);
  return new Date(year, month - 1, day);
}

// Tri décroissant par date
const arraybriefs = briefs.sort((a, b) => parseDate(b.date) - parseDate(a.date));

 
 return (
    <div style={{width:'100%'}}>
    

      <Grid   lg={12} sx={{pt:'24px', pl: '24px'}}>
            <Paper
                sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 'fit-content'
                }}
            >
                <h2 className="boldtitle">Liste des activités</h2>
                <TableContainer sx={{ maxHeight: 440, paddingLeft: 0, width:'100%' }}>
                    <List sx={{ width: '100%', padding: 0 }}>
                    {arraybriefs.map((brief, index) => {
                    const number = numberOfbriefs.filter(module => module.titre === brief.titre);

                            return (
                                <div key={brief.titre}>
                                        <Activity 
                                            isOpen={isOpen}
                                            setIsOpen={setIsOpen}
                                            titre={isLoading}
                                            setTitre={setTitre}
                                            isLoading={isLoading}
                                            setIsLoading={setIsLoading}
                                            setMessage={setMessage}
                                            message={message}
                                            handleClickOpen={handleClickOpen}
                                            setOpen={setOpen}
                                            open={open}
                                            brief={brief}
                                            number={number}
                                            parseDate={parseDate}
                                            today={today}
                                            classe={classe}
                                            allbriefs={allbriefs}
                                            briefs={numberOfbriefs}
                                        />
                                    <Divider />
                                </div>
                            );
                        })}
                    </List>
                </TableContainer>
            </Paper>
        </Grid>
    </div>
  );
};

export default Activities;
