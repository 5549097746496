import React, { Component } from 'react';
import Deposits from './Deposits';
import CurrentTopStudents from './CurrentTopStudents/CurrentTopStudents';
import GlobalGauge from './GlobalGauge/GlobalGauge';
import BaddestGauge from './BaddestGauge/BaddestGauge';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Events from './Events';
import Briefs from './Briefs';
import InfosCohorte from './InfosCohorte';
import Discipline from './Discipline';
import '../../App.css'



function Infos({isAnActivity, setIsAnActivity, topstudents, classe, filteredUsers, briefs}) {
  const [ouvrir, setOuvrir] = React.useState(false);
  const [selectedCompetence, setSelectedCompetence] = React.useState(null);

  const handleClickOpen = (competence) => {
      setSelectedCompetence(competence);
      setOuvrir(true);
  };

  const handleClose = () => {
      setOuvrir(false);
      setSelectedCompetence(null);
  };

  
    return ( 
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={3}>
          {/* Chart */}
          <Grid item xs={12} md={8} lg={6} sx={{display:'flex', flexDirection:'column', justifyContent:'space-between'}}>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                marginBottom:'20px'
              }}
              className='card-info-cohorte'
            >
              <InfosCohorte classe={filteredUsers}/>
            </Paper>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                minHeight: 180,
              }}
            >
              <Events/>
            </Paper>
          </Grid>
          <Grid item xs={12} md={8} lg={6} sx={{display:'flex', flexDirection:'column'}}>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                height: 'fit-content',
                marginBottom:'20px',
                
              
              }}
              className='size-box-brief'
            >

              <Briefs 
              filteredUsers={filteredUsers}
              ouvrir ={ouvrir}
              handleClickOpen={handleClickOpen}
              handleClose={handleClose}
              selectedCompetence={selectedCompetence}
              briefs={briefs}
              setIsAnActivity={setIsAnActivity}
              isAnActivity={isAnActivity}
              />
            </Paper>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <Discipline classe={classe}/>
            </Paper>
          </Grid>
          {/* Best Students */}
          <Grid item xs={12} md={8} lg={6}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height:'100%', overflow: 'hidden' }}>
              <CurrentTopStudents topstudents={topstudents} />
            </Paper>
          </Grid>
          {/* Global gauge */}
          <Grid item xs={12} md={4} lg={3}>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                height: 240,
              }}
            >
              <GlobalGauge classe={classe}/>
            </Paper>
          </Grid>

          {/* Baddest student gauge */}
          <Grid item xs={12} md={4} lg={3}>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                height: 240,
              }}
            >
              <BaddestGauge classe={classe} />
            </Paper>
          </Grid>
        </Grid>
      </Container>
     );
}

export default Infos;