import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import React, { useState, useEffect } from 'react';
import Title from './Title';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ClassIcon from '@mui/icons-material/Class';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import Divider from '@mui/material/Divider';
import "../../App.css";
import Activite from './Activite';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
function Activites({ filteredUsers, briefs }) {
    const [open, setOpen] = useState(false);
    const [selectedCompetence, setSelectedCompetence] = useState(null);
    
    const handleClickOpen = (competence) => {
        setSelectedCompetence(competence);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedCompetence(null);
    };

    // Vérifiez si filteredUsers est défini et non vide
    if (!filteredUsers || filteredUsers.length === 0 || !Array.isArray(filteredUsers[0].briefs)) {
        return <div>Aucune évaluation disponible.</div>;
    }
    function parseDate(dateStr) {
        const [day, month, year] = dateStr.split('-').map(Number);
        return new Date(year, month - 1, day);
    }
    const filtered = briefs.filter(brief => brief.id_etudiant === filteredUsers[0].id);
    const arraybriefs = filtered.sort((a, b) => parseDate(b.date) - parseDate(a.date));


    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={8} lg={12}>
                    <Paper
                        sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            height: "100%",
                        }}
                    >
                        <span style={{fontSize:'20px', fontWeight:'bold', textAlign:'center', color:'#ED462F', lineHeight:'14px'}}>Activités</span>
                        
                        <List style={{ width: '100%' }}>
                            {arraybriefs.map((competence, index) => (
                                <div key={index}>
                                    {competence.statut === 0 || competence.statut === 1 ? (
                                        <ListItemButton 
                                            className='box-brief'
                                            onClick={() => handleClickOpen(competence)}
                                            sx={{justifyContent:'space-between'}}
                                        >
                                            <div style={{ display: 'flex' }}>
                                                <div className="datebrief">
                                                    <ListItemIcon sx={{ minWidth: 'fit-content', marginRight: '10px', display:'flex', alignItems:'center' }}>
                                                        <HistoryEduIcon />
                                                    </ListItemIcon>
                                                </div>
                                                <span style={{ color: 'white' }}>
                                                    {competence.titre}
                                                </span>
                                            </div>
                                            <div style={{ display: 'flex' }} className='layout-evaluation'>
                                                <div className="datebrief">
                                                    <ListItemIcon  sx={{ minWidth: 'fit-content', marginRight: '10px', alignItems:'center' }}>
                                                        <AccessAlarmIcon />
                                                    </ListItemIcon>
                                                </div>
                                                <ListItemText sx={{ color: 'white', textAlign: 'right' }}>
                                                    {competence.statut === 0 ? competence.date || 'Date non définie' : 'Terminé'}
                                                </ListItemText>
                                            </div>
                                        </ListItemButton>
                                    ) : null}
                                    <Divider />
                                </div>
                            ))}
                        </List>
                    </Paper>
                </Grid>
            </Grid>
            {selectedCompetence && (
                <Activite 
                    open={open} 
                    handleClickOpen={handleClickOpen} 
                    handleClose={handleClose} 
                    competence={selectedCompetence} 
                    filteredUsers={filteredUsers}
                />
            )}
        </Container>
    );
}

export default Activites;
