import React from 'react';
import Title from './Title';
import '../../App.css'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

 function Events({}) {
    // const array=[
    //     {id:1, event:'Hackathon', date:'04/08/2024'},
    //     {id:1, event:'Rotation O\'lo hub', date:'04/08/2024'},
    //     {id:1, event:'Hackathon', date:'12/08/2024'},
    //     {id:1, event:'Hackathon', date:'04/08/2024'},
    // ]
    const array=[]
    return ( 
        <div>
            <span style={{fontSize:'20px', fontWeight:'bold', textAlign:'center', color:'#ED462F', lineHeight:'14px'}}>Évenements à venir</span>
         {array.length === 0 ? (
                <div>Aucun événement prévu.</div>
            ) : (
            <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell><strong>Évenements </strong> </TableCell>
            <TableCell><strong>Date</strong> </TableCell>

          </TableRow>
        </TableHead>
        <TableBody>
          {array.slice(0, 3).map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row.event}</TableCell>
              <TableCell>{row.date}</TableCell>


            </TableRow>
          ))}
        </TableBody>
            </Table>
            )}
        </div>
     );
 }
 
 export default Events;