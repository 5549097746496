import React from 'react';
import Box from '@mui/material/Box';
import '../../App.css';

function Skillslevels({ niveau }) {
  // Créez une fonction pour déterminer la classe CSS en fonction du niveau
  const getClassName = (level) => {
    return niveau >= level ? 'levelcircle done' : 'levelcircle';
  };

  return (
    <div style={{ position: 'relative', display: 'flex', alignItems: 'center', width:'fit-content', height:'55px' }}>
      <Box sx={{ position: 'absolute', display: 'flex', justifyContent: 'space-between' }} className='box-circle-skills'>
        <div className={getClassName(1)}>N1</div>
        <div className={getClassName(2)}>N2</div>
        <div className={getClassName(3)}>N3</div>
      </Box>
      <hr className='box-circle-skills'/>
    </div>
  );
}

export default Skillslevels;
