import React, { useState, useEffect } from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import { Gauge } from '@mui/x-charts/Gauge';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookSharpIcon from '@mui/icons-material/FacebookSharp';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import LanguageIcon from '@mui/icons-material/Language';
import GitHubIcon from '@mui/icons-material/GitHub';


function InfoEtudiant({selectedStudent}) {
    const borderColor = selectedStudent.discipline >= 5 ? 'green' : 'red';
    const [value, setValue] = React.useState(0);
    const lien = `${selectedStudent.photo}`;
    React.useEffect(() => {
      let objetAvecPlusPetiteSomme = null;
      let plusPetiteSomme = Infinity;
  
      if ([selectedStudent] && [selectedStudent].length > 0) {
        [selectedStudent].forEach(item => {
          const sommeNiveaux = item.competences.reduce((acc, competence) => acc + competence.niveau, 0);
  
          if (sommeNiveaux < plusPetiteSomme) {
            plusPetiteSomme = sommeNiveaux;
            objetAvecPlusPetiteSomme = item;
          }
        });
  
        if (objetAvecPlusPetiteSomme) {
          const nombreCompetences = objetAvecPlusPetiteSomme.competences.length;
          const ratio = Math.floor((plusPetiteSomme / (nombreCompetences * 3)) * 100);
          setValue(ratio);
        }
      } else {
        console.error("Le tableau `[selectedStudent]` est vide ou non défini.");
      }
    }, [[selectedStudent]]); // Le hook useEffect dépend de `classe`
    return ( 

                    <Grid item xs={12} md={4} lg={5}>
                        <Paper
                            sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'row ',
                            height: 'fit-content',
                            width:'100%',
                            }}
                        >
                            <div style={{width: "100px", height: "100px", marginRight: '30px'}}>
                            <Avatar
                                alt={`${selectedStudent.prenom} ${selectedStudent.nom}`}
                                src={lien}
                                sx={{ width: "100px", height: "100px", cursor: 'pointer' }}
                            />
                            </div>
                            <div>
                                <span style={{fontSize:'25px', fontWeight:'bold'}}>{`${selectedStudent.prenom} ${selectedStudent.nom}`}</span>
                                <div style={{display:'flex', justifyContent:'space-between'}}>
                                    <div>
                                        <span>{selectedStudent.specialisation}</span><br />
                                        <span>{selectedStudent.age} ans</span>
                                    </div>

                                    <div style={{display:'flex', marginLeft:'30px'}}>
                                        <div style={{ display: 'flex', alignItems: 'center'}} >
                                            <a href={`tel:00241${selectedStudent.phone}`}>
                                            <div className='circleiconlink'>
                                                <LocalPhoneIcon sx={{ height: '15px', color: 'white' }} />
                                            </div>
                                            </a>

                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center'}} >
                                            <a href={selectedStudent.linkedin}>
                                            <div className='circleiconlink'>
                                                <LinkedInIcon sx={{ height: '15px', color: 'white' }} />
                                            </div>
                                            </a>

                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center'}} >
                                            <a href={selectedStudent.facebook}>
                                            <div className='circleiconlink'>
                                                <FacebookSharpIcon sx={{ height: '15px', color: 'white' }} />
                                            </div>
                                            </a>

                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center'}} >
                                            <a href={`mailto:${selectedStudent.email}`}>
                                            <div className='circleiconlink'>
                                                <AlternateEmailIcon sx={{ height: '15px', color: 'white' }} />
                                            </div>
                                            </a>

                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center'}} >
                                            <a href={selectedStudent.github}>
                                            <div className='circleiconlink'>
                                                <GitHubIcon sx={{ height: '15px', color: 'white' }} />
                                            </div>
                                            </a>

                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center'}} >
                                            <a href={selectedStudent.site}>
                                            <div className='circleiconlink'>
                                                <LanguageIcon sx={{ height: '15px', color: 'white' }} />
                                            </div>
                                            </a>

                                        </div>

                                        
                                        
                                    </div>
                                </div>


                            </div>
                        </Paper>
                        <Paper
                            sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'row ',
                            height: 'fit-content',
                            width:'100%',
                            mt:'20px',
                            justifyContent:'space-around'
                            }}
                        >
                            <div>
                            <span style={{fontSize:'20px', fontWeight:'bold', textAlign:'center', color:'#ED462F'}}>Taux d'avancement</span>
                            <Gauge width={100} height={100} value={value} style={{ height: '150px', width: '100%' }} className='GlobalGauge' />
                            </div>
                            <div style={{
                                 display: 'flex', 
                                 alignItems: 'center', 
                                 flexDirection:'column'
                            }}>
                            <span style={{fontSize:'20px', fontWeight:'bold', textAlign:'center', marginBottom:'15px', color:'#ED462F'}}>Note de discipline</span>
                            <div 
                            id="circlemark" 
                            style={{ 
                              border: `10px solid ${borderColor}`, 
                              borderRadius: '50%', 
                              width: 120, 
                              height: 120, 
                              display: 'flex', 
                              alignItems: 'center', 
                              justifyContent: 'center'
                            }}
                          >
                            <span style={{fontSize:'27px'}}>{selectedStudent.discipline}</span>
                            
                          </div>                            </div>
                        </Paper>
                    </Grid>
               
     );
}

export default InfoEtudiant;