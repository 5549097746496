// firebase.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getMessaging, getToken, onMessage } from "firebase/messaging";


const firebaseConfig = {
    apiKey: "AIzaSyBFsTdqPSNL268ri7Xe0X5FxpS7nOIg1qc",
    authDomain: "auth-2a5c6.firebaseapp.com",
    projectId: "auth-2a5c6",
    storageBucket: "auth-2a5c6.appspot.com",
    messagingSenderId: "824837136835",
    appId: "1:824837136835:web:8a505fedeb1444935a5d76",
    measurementId: "G-522X2NX0E1"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth };

const messaging = getMessaging();
getToken(messaging, { vapidKey: 'BGJ1Uy2fUSQksq0xvm1_AfbhfQDNw2jaSVwnYNCwUsDFCckF6jjzIwEVNZFrmN__u5SrC_oTi2a3NcHbLGrryjM' }).then((currentToken) => {
  if (currentToken) {
    // Send the token to your server and update the UI if necessary
    console.log('Key available');

  } else {
    // Show permission request UI
    console.log('No registration token available. Request permission to generate one.');
    // ...
  }
}).catch((err) => {
  console.log('An error occurred while retrieving token. ', err);
  // ...
});
