import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import Typography from '@mui/material/Typography';
import ListItemButton from '@mui/material/ListItemButton';
import Avatar from '@mui/material/Avatar';
import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import Divider from '@mui/material/Divider';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function Brief({brief,parseDate, today}) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  
  return (
    <React.Fragment>
        <ListItemButton onClick={handleClickOpen}>
            <div className='row1'>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', padding: '15px 0', alignItems: 'center' }}>
                        
                        
                        <div style={{border: "1px solid transparent"}} className='marge'>
                            <span style={{ fontSize: '18px', color: 'white', fontWeight: '600',whiteSpace: "nowrap" }}>
                                {brief.titre} 
                            </span>
                        </div>
                        
                    </div>
                </div>
                <div style={{ display: 'flex', width:'185px', justifyContent:'space-between', height:'fit-content'}}>
                    
                    <div style={{ display: 'flex', alignItems: 'center'}}>
                        <div className='circleiconinfos'>
                            <PersonOutlineOutlinedIcon sx={{ height: '15px', color: '#1E1E1E' }} />
                        </div>
                        <span style={{ fontSize: '20px', color: 'white', width:'30px' }}>
                        {brief.apprenants.length}
                        </span>
                    </div>
                    <Divider orientation="vertical" variant="middle" flexItem />
                    <div style={{ display: 'flex', alignItems: 'center', marginLeft:'20px' }}>
                        <div className='circleiconinfos'>
                            <AccessAlarmsIcon sx={{ height: '15px', color: '#1E1E1E' }} />
                        </div>
                        <span style={{ fontSize: '20px', color: 'white', width:'110px', }}>{parseDate(brief.date) < today ? "Terminé" : brief.date}</span>
                    </div>
                </div>
            </div>

        </ListItemButton>


      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {brief.titre}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>

        <span style={{ fontWeight: 'bold' }}>Description:</span>
        <p>{brief.description || 'Message non disponible'}</p>
        <br />
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '95%' }}>
          <div>
            <span style={{ fontWeight: 'bold' }}>Date de livraison:</span><br />
            <p>{brief.date}</p>
          </div>
          <div>
            <span style={{ fontWeight: 'bold' }}>Pièce jointe:</span><br />
            <Button variant="outlined" startIcon={<DownloadIcon />} href={brief.source} download={brief.source}>
              Télécharger le fichier
              </Button>
          </div>
        </div>
        <br />
        <span style={{ fontWeight: 'bold' }}>Apprenants:</span><br />
        <div style={{ display: 'flex', flexWrap: 'wrap', paddingBottom: '50px', marginTop: '7px' }}>
          {/* {brief.apprenants.map((label) => (
            <div
              key={label.id}
              style={{
                display: 'flex',
                alignItems: 'center',
                padding: '10px 20px 10px 10px',
                backgroundColor: '#121212',
                borderRadius: '50px',
                width: 'fit-content',
                marginRight: '15px',
                marginBottom: '10px',
                border: '1px solid rgb(48 48 48)',
              }}>
              <Avatar alt={label.prenom} src={`${label.photo}`} sx={{ marginRight: '15px' }} />
              {label.prenom}
            </div>
          ))} */}
        </div>
        <br />
        <br />

        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Save changes
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}
