import './App.css';
import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Accueil from './Pages/Accueil';
import { AuthProvider, useAuth } from './UserContext';
import Notification from './Notification';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Dev from './Pages/Dev';
import Ref from './Pages/Ref';


function App() {

  const currentUser = useAuth();
  const darkTheme = createTheme({
    palette: {
      primary: {
        light: '#757ce8',
        main: '#ED462F',
        dark: '#002884',
        contrastText: '#fff',
      },
      secondary: {
        light: '#ff7961',
        main: '#f44336',
        dark: '#ba000d',
        contrastText: '#000',
      },
      mode: 'dark',
    },
  });
  


  
  return (
    
    <div className="App">
        <ThemeProvider theme={darkTheme}>
        <BrowserRouter>
        <AuthProvider>
          <Routes>
            <Route path="/" element={<Accueil/>} /> 
            <Route path="/dev" element={<Dev/>} /> 
            <Route path="/ref" element={<Ref/>} /> 
          </Routes>
        </AuthProvider>
        </BrowserRouter>
        <Notification/>
        </ThemeProvider>
    </div>

  );
}

export default App;
