import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import Typography from '@mui/material/Typography';
import ListItemButton from '@mui/material/ListItemButton';
import Avatar from '@mui/material/Avatar';
import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import Divider from '@mui/material/Divider';
import axios from 'axios';
import DeleteBrief from "./DeleteBrief"


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function Activity({briefs, isOpen, setIsOpen, setIsLoading, isLoading, allbriefs,setMessage, brief,parseDate, today, number, classe}) {
  const [open, setOpen] = React.useState(false);
  const [data, setdata] = React.useState({});

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setIsOpen(true)
    setOpen(false);
  };

  const handleDelete = async () => {
    if (!brief.titre) {
      alert('Veuillez entrer un titre pour supprimer le brief.');
      return;
    }

    setIsLoading(true);

    try {
      // Envoyer une requête DELETE à l'API
      const response = await axios.delete('https://backend-codeschool241.onrender.com/briefs', {
        headers: {
          'Content-Type': 'application/json'
        },
        data: {
          titre: brief.titre
        }
      });

      // Afficher un message de succès
      setMessage(`Briefs supprimés avec succès. Nombre de briefs affectés: ${response.data.affectedRows}`);
    } catch (error) {
      console.error('Erreur lors de la suppression des briefs:', error);
      setMessage('Erreur lors de la suppression des briefs.');
    } finally {
      setIsLoading(false);
      window.location.reload()
    }
  };

  
  

  const reponses = []
  for (let index = 0; index < allbriefs.length; index++) {
    const filteredresponses = briefs.filter(module => module.id === allbriefs[index].id_brief)[0].titre;
    if (filteredresponses === brief.titre) {
      reponses.push(allbriefs[index])
      
    }
    


    
  }
  const [isLoading2, setIsLoading2] = React.useState(false); // Pour l'effet de chargement
  const [notification, setNotification] = React.useState({ open: false, success: false }); // Pour la notification

  return (
    <React.Fragment>
        <ListItemButton onClick={handleClickOpen}>
            <div className='row1'>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', padding: '15px 0', alignItems: 'center' }}>
                        
                        
                        <div style={{border: "1px solid transparent"}} className='marge'>
                            <span style={{ fontSize: '18px', color: 'white', fontWeight: '600',whiteSpace: "nowrap" }}>
                                {brief.titre} 
                            </span>
                        </div>
                        
                    </div>
                </div>
                <div style={{ display: 'flex', width:'185px', justifyContent:'space-between', height:'fit-content'}}>
                    
                    <div style={{ display: 'flex', alignItems: 'center'}}>
                        <div className='circleiconinfos'>
                            <PersonOutlineOutlinedIcon sx={{ height: '15px', color: '#1E1E1E' }} />
                        </div>
                        <span style={{ fontSize: '20px', color: 'white', width:'30px' }}>
                        {number.length}
                        </span>
                    </div>
                    <Divider orientation="vertical" variant="middle" flexItem />
                    <div style={{ display: 'flex', alignItems: 'center', marginLeft:'20px' }}>
                        <div className='circleiconinfos'>
                            <AccessAlarmsIcon sx={{ height: '15px', color: '#1E1E1E' }} />
                        </div>
                        <span style={{ fontSize: '20px', color: 'white', width:'110px', }}>{parseDate(brief.date) < today ? "Terminé" : brief.date}</span>
                    </div>
                </div>
            </div>

        </ListItemButton>


      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}

        
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {brief.titre}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
 
        {isOpen ? (
          <>
        <DialogContent dividers>

        <span style={{ fontWeight: 'bold' }}>Description:</span>
        <p>{brief.description || 'Message non disponible'}</p>
        <br />
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '95%' }}>
          <div>
            <span style={{ fontWeight: 'bold' }}>Date de livraison:</span><br />
            <p>{brief.date}</p>
          </div>
          <div>
            <span style={{ fontWeight: 'bold' }}>Pièce jointe:</span><br />
            <Button variant="outlined" startIcon={<DownloadIcon />} href={brief.source} download={brief.source}>
              Télécharger le fichier
              </Button>
          </div>
        </div>
        <br />
        <span style={{ fontWeight: 'bold' }}>Apprenants:</span><br />
        <div style={{ display: 'flex', flexWrap: 'wrap', paddingBottom: '50px', marginTop: '7px' }}>
          {number.map((label) => {
           const etudiant = classe.filter(item => item.id === label.id_etudiant);
           
            return(
            <div
              key={label.id_etudiant}
              style={{
                display: 'flex',
                alignItems: 'center',
                padding: '10px 20px 10px 10px',
                backgroundColor: '#121212',
                borderRadius: '50px',
                width: 'fit-content',
                marginRight: '15px',
                marginBottom: '10px',
                border: '1px solid rgb(48 48 48)',
              }}>
              <Avatar alt={`${etudiant[0].prenom}`} src={`${etudiant[0].photo}`} sx={{ marginRight: '15px' }} />
              {etudiant[0].prenom}
            </div>
           )})}
        </div>

        <span style={{ fontWeight: 'bold' }}>Réponses:</span><br />
        <div>
         {reponses.map((reponse, index) => {
            console.log(reponse);
            const statut = briefs.filter(module => module.id === reponse.id_brief)[0].statut;
            const prenom = classe.filter(module => module.id === reponse.id_etudiant)[0].prenom;
            const idBrief = briefs.filter(module => module.id === 70);
            console.log(statut);
            console.log(reponse.id_etudiant);
            

            const renderStatus = () => {
              switch (statut) {
                case 1:
                  return <span style={{fontWeigth:'600', color:'white'}}>En attente</span>;
                case 2:
                  return <span style={{fontWeigth:'600', color:'green'}}>Validé</span>;
                case 3:
                  return <span style={{fontWeigth:'600', color:'red'}}>Invalidé</span>;
                default:
                  return <span>Status inconnu</span>;
              }
            };
            const briefToChangeStatus = briefs.filter(item => item.id === data.id_brief);
            console.log(briefToChangeStatus);
            const click = () => {
              setIsOpen(false); 
              setdata(reponse)
              
              


            }
            

            return(     
          <ListItemButton onClick={click}  key={index} sx={{paddingLeft:0, paddingRight:0}}>
            <div style={{padding: '4px 0', width:'100%'}}>
              <div style={{padding: '5px 5px', display:'flex', justifyContent:'space-between'}}>
                <span>{prenom}</span>
                {renderStatus()}
              </div>
              <Divider/>
            </div>
          </ListItemButton>
          )})}

        </div>
        <br />


        </DialogContent>
        <DialogActions>   
          <DeleteBrief handleDelete={handleDelete}/>

        </DialogActions>
        </>
        ): (
        <>
       {data ? (
  <>
    <DialogContent dividers>
      <div style={{ minHeight: '400px', width: '600px' }}>
        <h2>{data.id_etudiant}</h2>

        <span style={{ fontWeight: '600' }}>Message:</span><br />
        <p>{data.message}</p><br />

        <span style={{ fontWeight: '600' }}>Lien:</span><br />
        {data.lien ? (
          <a href={data.lien.startsWith('https://') ? data.lien : `https://${data.lien}`}>
            {data.lien.startsWith('https://') ? data.lien : `https://${data.lien}`}
          </a>
        ) : (
          <p>Aucun lien disponible</p>
        )}

      </div>
    </DialogContent>
    <DialogActions>
  <Button 
    autoFocus 
    onClick={async () => {
      setIsLoading2(true); // Démarre le chargement
      try {
        // Met à jour le statut du brief avec axios
        await axios.put(`https://backend-codeschool241.onrender.com/brief/${data.id_brief}`, { statut: 3 });
  
        // Affiche la notification de succès
        setNotification({ open: true, success: true });
          setNotification({ open: false });
          window.location.reload(); // Recharge la page après 3 secondes
  
      } catch (error) {
        console.error('Erreur lors de la mise à jour du statut du brief :', error);
  
        // Affiche la notification d'échec
        setNotification({ open: true, success: false });
          setNotification({ open: false });
      } finally {
        setIsLoading2(false); // Arrête le chargement
      }
    }}
    sx={{ color: 'red' }}
  >
    Invalider
  </Button>
  
  <Button 
    autoFocus 
    onClick={async () => {
      setIsLoading2(true); // Démarre le chargement
      try {
        // Met à jour le statut du brief avec axios
        await axios.put(`https://backend-codeschool241.onrender.com/brief/${data.id_brief}`, { statut: 2 });
  
        // Affiche la notification de succès
        setNotification({ open: true, success: true });
          setNotification({ open: false });
          window.location.reload(); // Recharge la page après 3 secondes
  
      } catch (error) {
        console.error('Erreur lors de la mise à jour du statut du brief :', error);
  
        // Affiche la notification d'échec
        setNotification({ open: true, success: false });
          setNotification({ open: false });
      } finally {
        setIsLoading2(false); // Arrête le chargement
      }
    }}
    sx={{ color: 'green' }}
  >
    Valider
  </Button>
</DialogActions>


  </>
) : (
  <p>Les données ne sont pas disponibles.</p>
)}

        </>

        )}



      </BootstrapDialog>
    </React.Fragment>
  );
}
