import * as React from 'react';
import Stack from '@mui/material/Stack';
import { Gauge } from '@mui/x-charts/Gauge';
import Title from '../Title';
import "./BaddestGauge.css";

export default function BaddestGauge({ classe }) {
  const [value, setValue] = React.useState(0);

  React.useEffect(() => {
    let objetAvecPlusPetiteSomme = null;
    let plusPetiteSomme = Infinity;

    if (classe && classe.length > 0) {
      classe.forEach(item => {
        const sommeNiveaux = item.competences.reduce((acc, competence) => acc + competence.niveau, 0);

        if (sommeNiveaux < plusPetiteSomme) {
          plusPetiteSomme = sommeNiveaux;
          objetAvecPlusPetiteSomme = item;
        }
      });

      if (objetAvecPlusPetiteSomme) {
        const nombreCompetences = objetAvecPlusPetiteSomme.competences.length;
        const ratio = Math.floor((plusPetiteSomme / (nombreCompetences * 3)) * 100);
        setValue(ratio);
      }
    } else {
      console.error("Le tableau `classe` est vide ou non défini.");
    }
  }, [classe]); // Le hook useEffect dépend de `classe`

  return (
    <Stack direction={{ xs: 'column', md: 'column' }} spacing={{ xs: 1, md: 3 }} style={{ height: '100%', width: '100%' }} className='BaddestGauge'>
      <span style={{fontSize:'20px', fontWeight:'bold', textAlign:'center', color:'#ED462F', lineHeight:'14px'}}>Pire avancement <span style={{fontSize:'12px'}}> <br />  (individuel)</span></span>
      <Gauge width={100} height={100} value={value} style={{ height: '100%', width: '100%' }} className='baddestgauge' />
    </Stack>
  );
}
