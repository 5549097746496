import React from 'react';
import './SignIn.css';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../firebasec';
import { useState } from 'react';

function SignIn({setIsloggedIn, setUserID}) {
  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   const data = new FormData(event.currentTarget);
  //   console.log(data.get('email'));


  //   if(data.get('email')==='yohanndian@gmail.com' && data.get('password')==='ecole241'){
  //     setIsloggedIn(true)
  //     alert('Connexion reussi !')

  //   }
  //   else{
  //     setIsloggedIn(false)
  //     alert('Adresse mail ou mot de passe erroné !')
  //   }
  // };
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSignIn = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      setIsloggedIn(true)
      setUserID(email)
      console.log(email)
      console.log(auth)
    } catch (error) {
      alert(error.message);
    }
  };




  
  return (
    <div>
      <header>
        <div className="nav-container">
          <div className="logo">
            <img src="/img/logo.png" alt="Logo" className="logo-img" />
          </div>
        </div>
      </header>
      <div className="container">
        <div className="logo">
          <h1><span className="highlight">La plateforme</span> collaborative d'apprentissage et d'évaluation en pédagogie active</h1>
        </div>
        <div className="login-form">
          <h2>Connexion</h2>
          <form onSubmit={handleSignIn}>
            <label htmlFor="email">Adresse email*</label>
            <div className='input-box'>
              <input type="email" id="email" name="email" required placeholder="Format: exemple@mail.com"value={email} onChange={(e) => setEmail(e.target.value)}/>
            </div>

            
            
            <label htmlFor="password">Mot de passe*</label>
            <div className='input-box'>
              <input type="password" id="password" name="password" required value={password} onChange={(e) => setPassword(e.target.value)} />
            </div>
            
            <a href="#">Mot de passe oublié ?</a>
            
            <button type="submit">Se connecter</button>
          </form>
          <p>Vous n'avez pas encore vos identifiants ?<br />
             Demandez votre invitation à :</p>
          <ul>
            <li>votre formateur ou formatrice si vous êtes apprenant</li>
            <br/>
            <li>votre responsable si vous êtes formateur ou formatrice</li>
          </ul>
        </div>
      </div>
      <footer>
        <p>&copy; 2024 Tous droits réservés. Développé par Yohan et Jesse.</p>
      </footer>
    </div>
  );
}

export default SignIn;
