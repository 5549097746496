import React, { useState, useEffect } from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import DashboardRef from '../Composants/AdminDashboard/DashboardRef';

function Ref() {

    
    return ( 
        <div>
            <DashboardRef/>
        </div>
     );
}

export default Ref;