import React, { useState, useEffect } from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import axios from 'axios';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import '../../App.css';
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "../../firebase";

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const AvatarContainer = styled('div')({
  position: 'relative',
  display: 'inline-block',
});

const IconOverlay = styled(IconButton)({
  position: 'absolute',
  bottom: 0,
  right: '15px',
  backgroundColor: '#ED462F',
  borderRadius: '50%',
  padding: 4,
  zIndex: 1
});

function ChangeInformations({ filteredUsers }) {
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
  const [user, setUser] = useState({});
  const [lien, setLien] = useState('');
  const [image, setImage] = useState('');
  const [photo, setphoto] = useState(filteredUsers[0].photo);
  const [fichier, setFichier] = useState(null);
  const [file, setfile] = useState(null);
  const [prenom, setPrenom] = useState('');
  const [nom, setNom] = useState('');
  const [formData, setFormData] = useState({
    nom: '',
    prenom: '',
    photo: '/',
  });
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState({ open: false, message: '', severity: 'success' });

  useEffect(() => {
    console.log(filteredUsers);
    
    if (filteredUsers && filteredUsers.length > 0) {
      const userData = filteredUsers[0];
      setUser(userData);
      setLien(`${userData.photo}`);
      setImage(`${userData.photo}`);
      setPrenom(userData.prenom || '');
      setNom(userData.nom || '');
      setFormData({
        nom: userData.nom || '',
        prenom: userData.prenom || '',
        photo: userData.photo || '/',
      });
    }
  }, [filteredUsers]);

  const handleImageChange = (event) => {
    const item= event.target.files[0];
    handleUpload(item)

      const imageUrl = URL.createObjectURL(event.target.files[0]);
      setImage(imageUrl);

  };

  const handlePrenomChange = (event) => {
    const newPrenom = event.target.value;
    setPrenom(newPrenom);
    setFormData((prev) => ({
      ...prev,
      prenom: newPrenom,
    }));
  };

  const handleNomChange = (event) => {
    const newNom = event.target.value;
    setNom(newNom);
    setFormData((prev) => ({
      ...prev,
      nom: newNom,
    }));
  };
  const handleUpload = (item) => {
    if (!item) return;

    const storageRef = ref(storage, `/files/${item.name}`);
    const uploadTask = uploadBytesResumable(storageRef, item);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
      },
      (error) => {
        console.log(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          console.log("File available at", downloadURL);
          setphoto(downloadURL);
        });
      }
    );
  };
  const send = async () => {
    setLoading(true);
    const uploadData = new FormData();
    uploadData.append('file', fichier);

    // if (!file) {
    //   alert('no')
    //   try {
    //     setSuccess("Informations à jour avec succès!");

    //     window.location.reload();
    //   } catch (error) {

    //     setError("Erreur lors de la mise à jour des données!");

    //   } finally {
    //     setLoading(false);
    //   }
    // } else {
      try {        
        await axios.put(`https://backend-codeschool241.onrender.com/${user.competences ? 'etudiant' : 'admin'}/${user.id}`, {
          ...formData,
          photo: photo,
        });
        

        window.location.reload();
      } catch (error) {
        console.error("Erreur lors de l'upload du fichier ou de la mise à jour des données:", error);
        setError("Erreur lors de la mise à jour des données!");

      } finally {


        setSuccess("Photo mise à jour avec succès!");
        setLoading(false);
      }
    
  };

  const handleCloseNotification = () => {
    setNotification({ ...notification, open: false });
  };

  return (
    <div>
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4} lg={4}>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                height: 'fit-content',
                justifyContent: 'space-between',
              }}
            >
              <h2 className='boldtitle' style={{textAlign:'center'}}>Changer ses informations</h2>
              {error && <Alert severity="error">{error}</Alert>}
              {success && <Alert severity="success">{success}</Alert>}
              <div style={{ display: 'flex', margin: 'auto' }}>
                <AvatarContainer>
                  <Avatar
                    alt={`${prenom} ${nom}`}
                    src={image}
                    sx={{ width: 100, height: 100, marginRight: 2, cursor: 'pointer' }}
                    onClick={() => document.getElementById('file-input').click()}
                  />
                  <IconOverlay
                    onClick={() => document.getElementById('file-input').click()}
                  >
                    <AddIcon />
                  </IconOverlay>
                </AvatarContainer>
                <VisuallyHiddenInput
                  id="file-input"
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                />
              </div>
              <br />
              <div>
                <TextField
                  label="Prénom"
                  variant="outlined"
                  value={prenom}
                  onChange={handlePrenomChange}
                  fullWidth
                  sx={{ marginBottom: 2 }}
                  inputProps={{ style: { color: 'white' } }} // Pour le texte du champ
                  InputLabelProps={{ style: { color: 'white' } }} // Pour le label
                />
                <TextField
                  label="Nom"
                  variant="outlined"
                  value={nom}
                  onChange={handleNomChange}
                  fullWidth
                  sx={{ color: 'white' }}
                  inputProps={{ style: { color: 'white' } }} // Pour le texte du champ
                  InputLabelProps={{ style: { color: 'white' } }} // Pour le label
                />
                <Button
                  variant="contained"
                  sx={{ marginTop: '15px', width: '100%' }}
                  onClick={send}
                  disabled={loading} // Désactiver le bouton pendant le chargement
                >
                  {loading ? 'Envoi en cours...' : 'Enregistrer'}
                </Button>
              </div>
            </Paper>
          </Grid>
        </Grid>
      </Container>

      <Snackbar
        open={notification.open}
        autoHideDuration={6000}
        onClose={handleCloseNotification}
      >
        <Alert onClose={handleCloseNotification} severity={notification.severity}>
          {notification.message}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default ChangeInformations;
